import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Landing from './pages/Landing';
import PreLaunch from './pages/PreLaunch';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Navbar from './components/Navbar';
import Privacy from './pages/Privacy';

const App = () => {
  return (
    <div className="App">
    <Router>
      <Navbar />
      <Routes>
        <Route 
          path="/pre-launch"
          element={<PublicRoute component={PreLaunch} />} 
        />
        <Route 
          path="/privacy"
          element={<PublicRoute component={Privacy} />} 
        />
        <Route path="/" element={<Navigate to="/pre-launch" />} />
        {/* <Route 
          path="/"
          element={<PublicRoute component={Landing} />} 
        /> */}
        <Route 
          path="/login" 
          element={<PublicRoute component={Login} restricted />} 
        />
        <Route 
          path="/signup" 
          element={<PublicRoute component={Signup} restricted />} 
        />
        <Route 
          path="/landing" 
          element={<PublicRoute component={Landing} />} 
        />
        <Route 
          path="/home" 
          element={<PrivateRoute component={Home} />} 
        />
      </Routes>
      <Footer />
    </Router>
    </div>
  );
};

export default App;