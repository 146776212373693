import React from 'react';

const SplitLayout = ({ children, illustration }) => {
  return (
    <div className="split-layout">
      <div className="split-layout__left">
        {children}
      </div>
      <div className="split-layout__right">
        <img src={illustration} alt="Illustration" />
      </div>
    </div>
  );
};

export default SplitLayout;