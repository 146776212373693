import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SplitLayout from '../components/SplitLayout';
import signupIllustration from '../assets/illustration.svg';
import { register } from '../services/authService';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const setGeneralMsg = useGeneralMsgUpdate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if(errors[name]){
      setErrors({
        ...errors,
        [name]: undefined
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    const { firstName, lastName, email, password, confirmPassword } = formData;

    if (!firstName) newErrors.firstName = 'First name is required';
    if (!lastName) newErrors.lastName = 'Last name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!password) {
      newErrors.password = 'Password is required';
    } else {
      const errors = [];
      if (password.length < 8) errors.push('be at least 8 characters long');
      if (!/[A-Z]/.test(password)) errors.push('have at least one uppercase letter');
      if (!/[a-z]/.test(password)) errors.push('have at least one lowercase letter');

      if (errors.length > 0) {
        if (errors.length > 1) {
          const lastError = errors.pop();
          newErrors.password = `Password must ${errors.join(', ')} and ${lastError}.`;
        } else {
          newErrors.password = `Password must ${errors[0]}.`;
        }
      }

    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);

      try {
        await register(formData.firstName, formData.lastName, formData.email, formData.password);
        navigate('/login');
      } catch (error) {
        const errorMsg = error? error : 'An unknown error occurred';
        setGeneralMsg(errorMsg, 'error');  
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <SplitLayout illustration={signupIllustration}>
      <div className="signup-form-container">
        <h1>Create an Account</h1>
        <p className='description'>Sign up to start adding notes to your LinkedIn connections.</p>
        <form onSubmit={handleSubmit} className='signup-form'>
            <div className='name-container'> 
            <input
              type="text"
              name="firstName"
              placeholder='First Name'
              value={formData.firstName}
              onChange={handleChange}
              required
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
            <input
              type="text"
              name="lastName"
              placeholder='Last Name'
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
            </div>
            
            <input
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}

            <div className="password-input-container">
              <input
                type={passwordVisible ? 'text' : 'password'}
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible((prevState) => !prevState)}
              >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.password && <span className="error">{errors.password}</span>}

            <div className="password-input-container">
              <input
                type={confirmPasswordVisible ? 'text' : 'password'}
                name="confirmPassword"
                placeholder='Confirm Password'
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setConfirmPasswordVisible((prevState) => !prevState)}
              >
                {!confirmPasswordVisible ? <FiEyeOff /> : <FiEye />}
              </button>
            </div>
            {errors.confirmPassword && <span className="error">{errors.confirmPassword}</span>}

          <button className='signup-btn' type="submit" disabled={isLoading}>
            {isLoading ? 'Signing up...' : 'Sign Up'}
          </button>
          <p>Already have an account? <Link to="/login" className='color-blue'>Log in</Link></p>
        </form>
      </div>
    </SplitLayout>
  );
};

export default Signup;