// Privacy.js
import React from 'react';

const Privacy = () => {
  return (
    <div className="privacy-container">
        <h1>Privacy Policy and Permissions Justification</h1>
        <section>
          <h2>Single Purpose</h2>
          <p>
            The LinknTrack Chrome Extension is designed to help users add, manage, and organize personalized notes directly on LinkedIn profiles. It allows professionals to keep track of key details about their connections and access these notes seamlessly while browsing LinkedIn.
          </p>
        </section>
        <section>
          <h2>Privacy Policy</h2>
          <h3>1. Information We Collect</h3>
          <p>
            LinknTrack Chrome Extension does not collect, store, or transmit any personal data beyond what is necessary for the functionality of the service. When users authenticate through the LinknTrack website, their credentials are safely hashed and stored. Notes added by users are encrypted and stored for future access. No personal data is stored in plaintext.
          </p>
          <h3>2. Data Usage</h3>
          <p>
            The LinknTrack Chrome Extension uses only the necessary data for its core functionality. Your notes are securely stored on the server in an encrypted format. Authentication is managed through a secure JWT, and user credentials are hashed for safety.
          </p>
          <h3>3. Third-Party Access</h3>
          <p>
            No third parties have access to any data processed by the LinknTrack Extension. The extension does not interact with any external APIs or third-party services except for the authentication token from the website used for authentication management.
          </p>
          <h3>4. Changes to the Privacy Policy</h3>
          <p>
            We reserve the right to update this privacy policy. Any updates will be reflected in the latest version through the extension and the website.
          </p>
        </section>
        <section>
          <h2>Permissions Justifications</h2>
          <h3>Tabs</h3>
          <p>
            <strong>Justification:</strong> The tabs permission enables the extension to interact with browser tabs, such as detecting when a LinkedIn profile page is open. It allows the extension to add notes to those pages dynamically and interact with the content on the tab.
          </p>
          <h3>Storage</h3>
          <p>
            <strong>Justification:</strong> The storage permission is required to store data locally in the browser, such as user preferences (e.g., the last position of the dragged notes or the state of the note's expand/collapse behavior). This ensures a more personalized and seamless user experience.
          </p>
          <h3>Scripting</h3>
          <p>
            <strong>Justification:</strong> The scripting permission allows the extension to programmatically inject JavaScript into LinkedIn pages, which is necessary for adding, updating, and removing notes from profiles dynamically. This permission ensures that the extension can modify the content of the page in real time to interact with user input and save notes.
          </p>
        </section>
    </div>
  );
};

export default Privacy;