import React, { useState } from 'react'
import SplitLayout from '../components/SplitLayout';
import preLaunchIllustration from '../assets/illustration.svg';
import { subscribeEmail } from '../services/prelaunchService';

function PreLaunch() {
    const [email, setEmail] = useState('');
    const [buttonText, setButtonText] = useState('Join the waitlist →');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showingMessage, setShowingMessage] = useState(false);

    const handleEmailChange = (e) => {
      setEmail(e.target.value);
      if (showingMessage) {
        setButtonText('Join the waitlist →');
        setShowingMessage(false);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault(); 
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!email || !emailPattern.test(email)) {
        setButtonText('Please enter a valid email');
        setShowingMessage(true);
        return;
      }
  
      setIsSubmitting(true);
      setButtonText('Sending...');
  
      try {
        const message = await subscribeEmail(email); 
        setButtonText(message);
        if (message === 'Successfully joined!') {
          setEmail('');
        }
        setShowingMessage(true); 
      } catch (error) {
        setButtonText(error);
        setShowingMessage(true);
      } finally {
        setIsSubmitting(false);
      }

    };
  
    return (
      <SplitLayout illustration={preLaunchIllustration}>
        <div className="pre-launch">
          <h1>Your Note Taking Assistant</h1>
          <p>
            Effortlessly track, remember, and grow your connections through personalized notes.
            <span> Soon available for LinkedIn Users</span>
          </p>
          <form className="email-subscription" onSubmit={handleSubmit}>
            <input 
              type="email" 
              placeholder="Enter your email..." 
              value={email} 
              onChange={handleEmailChange}
              disabled={isSubmitting}
            />
            <button 
              type="submit"
              disabled={isSubmitting || showingMessage}
            >
              {buttonText}
            </button>
          </form>
        </div>
      </SplitLayout>
    );
    
}

export default PreLaunch
