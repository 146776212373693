import React from 'react'; 
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PublicRoute = ({ component: Component, restricted }) => {
  const { user } = useAuth();

  return user && restricted ? <Navigate to="/home" /> : <Component />;
};

export default PublicRoute;