import React from 'react';
import { Link } from 'react-router-dom';
// TODO: Decide what will be shown here

const Landing = () => {
  return (
    <div className="landing">
      <h1>Welcome to LinkedIn Notes</h1>
      <p>Organize your LinkedIn connections with personalized notes.</p>
      <p>
        <strong>Get started today!</strong>
      </p>
      <div className="home-actions">
        <Link to="/login" className="btn">Log In</Link>
        <Link to="/signup" className="btn">Sign Up</Link>
      </div>
    </div>
  );
};

export default Landing;