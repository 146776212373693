import API from './api';

export const subscribeEmail = async (email) => {
  try {
    const response = await API.post('/prelaunch/subscribe', { email });
    return response.data.message; // This will be the success message like "Successfully joined!" or error message
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message // Handle known error response like "Email already subscribed"
      : 'An error occurred during subscription.';
  }
};