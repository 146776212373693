import axios from 'axios';
// import Cookies from 'js-cookie';

const API = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

// API.interceptors.request.use((req) => {  
//     const token = Cookies.get('token');
//     if (token) {
//       req.headers.Authorization = `Bearer ${token}`;
//     }
//     return req;
// });

export default API;