import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';

const Navbar = () => {
  const { logout, user } = useAuth();
  const setGeneralMsg = useGeneralMsgUpdate();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const handleLogout = async() => {
    setIsLoggingOut(true);
    try {
      await logout();
    } catch (error) {
      const errorMsg = error? error : 'An unknown error occurred';
      setGeneralMsg(errorMsg, 'error');  
    } finally {
      setIsLoggingOut(false);
    }

  };

  return (
    <nav className="navbar">
      <p><span className='nav-l'>L</span>ink<span className='nav-n'>n</span>Track</p>
      <div className="nav-links">
      {user && (
          <button
            className="logout-btn"
            onClick={handleLogout}
            disabled={isLoggingOut}
          >
            {isLoggingOut ? 'Logging out...' : 'Logout'}
          </button>
        )}        
        
        {/* <Link to="/login">Login</Link>
        <Link to="/signup">Signup</Link> */}
      </div>
    </nav>
  );
};

export default Navbar;