import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SplitLayout from '../components/SplitLayout';
import loginIllustration from '../assets/illustration.svg';
import { useAuth } from '../context/AuthContext'; 
import { useGeneralMsgUpdate } from '../context/GenralMsgContext';
import { FiEye, FiEyeOff } from 'react-icons/fi';

const Login = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const setGeneralMsg = useGeneralMsgUpdate();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await login(formData.email, formData.password);
      navigate('/home');
    } catch (error) {
      const errorMsg = error? error : 'An unknown error occurred';
      setGeneralMsg(errorMsg, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SplitLayout illustration={loginIllustration}>
      <div className="login-form-container">
        <h1>Welcome Back!</h1>
        <p className='description'>Log in to access your LinkedIn notes and manage your connections.</p>
        <form onSubmit={handleSubmit} className='login-form'>
            <input
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
              required
            />
            <div className="password-input-container">
              <input
                type={passwordVisible ? 'text' : 'password'} // Toggle between 'text' and 'password'
                name="password"
                placeholder='Password'
                value={formData.password}
                onChange={handleChange}
                required
              />
              <button
                type="button"
                className="password-toggle"
                onClick={() => setPasswordVisible(prevState => !prevState)} // Toggle password visibility
              >
                {!passwordVisible ? <FiEyeOff /> : <FiEye />} {/* Use icons instead of text */}
              </button>
            </div>
            {/* TODO: add forgot password */}
          <button className='login-button' type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
          <p>Don't have an account? <Link to="/signup" className='color-blue'>Sign up</Link></p>
        </form>
      </div>
    </SplitLayout>
  );
};

export default Login;