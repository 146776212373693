import API from './api';

// Login user
export const login = async (email, password) => {
  try {
    const response = await API.post('/auth/login', { email, password });
    return response.data; // No need to handle token directly
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during login.';
  }
};

// Logout user
export const logout = async () => {
  try {
    await API.post('/auth/logout'); // Backend should clear the token cookie
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during Logout.';
  }
};

// Register user
export const register = async (firstName, lastName, email, password) => {
  try {
    await API.post('/auth/register', { firstName, lastName, email, password });
  } catch (error) {
    throw error.response && error.response.data
      ? error.response.data.message
      : 'An error occurred during registration.';
  }
};

// Check authentication status
export const checkAuth = async () => {
  try {
    const response = await API.get('/users/me'); // Backend determines auth status
    return response.data;
  } catch (error) {
    return null;
  }
};