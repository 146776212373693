import React from 'react';
// TODO: Add loading element

const Loading = () => {
  return (
    <div className='loading-container'>
      <p>Loading</p>
    </div>
  );
};

export default Loading;